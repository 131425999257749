<template>
    <div>
        <b-overlay
            :show="isLoading"
            rounded
            opacity="0.6"
            spinner-variant="primary"
            spinner-mediam
        >
            <transition
                :name="$store.state.appConfig.layout.routerTransition"
                mode="out-in"
            >
                <b-row>
                    <b-col md="9">
                        <div class="text-center">
                            <p><b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Qoyod-logo.png" height="100" /></p>
                            <p>{{$t('Qoyod offers you an easy accounting system to organize the accounting work for your business')}}</p>
                            <p><a href="https://www.qoyod.com">www.qoyod.com</a></p>
                        </div>
                        <b-card>
                            <h4 class="text-primary">{{$t('Public Settings')}}</h4>  
                            <p>{{$t('From the form below you can set everything about the integration with Qoyod')}}</p> 

                            <b-form @submit.prevent="save()">
                                <b-form-group
                                :label="$t('Enable Integration ?')"
                                label-for="v-is_active"
                                >
                                    <!-- is_active -->
                                    <b-form-checkbox
                                        id="v-is_active"
                                        class="custom-control-primary"
                                        v-model="config.is_active"
                                        name="is_active"
                                        switch
                                    >
                                        <span class="switch-icon-left">
                                            <feather-icon icon="CheckIcon" />
                                        </span>
                                        <span class="switch-icon-right">
                                            <feather-icon icon="XIcon" />
                                        </span>
                                    </b-form-checkbox>
                                </b-form-group>

                                <!-- api_key -->
                                <b-form-group
                                    :label="$t('API Key')"
                                    label-for="v-api-key"
                                >
                                    <b-form-input
                                        id="v-api-key"
                                        type="text"
                                        v-model="config.api_key"
                                    />
                                </b-form-group>

                                <!-- apply_vat -->
                                <b-form-group
                                    :label="$t('Apply VAT')"
                                    label-for="v-apply_vat"
                                >
                                    <b-form-select
                                        id="v-type"
                                        v-model="config.apply_vat"
                                        :options="vat_options"
                                    />
                                </b-form-group>

                                <!-- sync_quantites -->
                                <b-form-group
                                :label="$t('Enable sync quantities')"
                                label-for="v-sync_quantites"
                                >
                                    <b-form-checkbox
                                        id="v-sync_quantites"
                                        class="custom-control-primary"
                                        v-model="config.sync_quantites"
                                        name="sync_quantites"
                                        switch
                                    >
                                        <span class="switch-icon-left">
                                            <feather-icon icon="CheckIcon" />
                                        </span>
                                        <span class="switch-icon-right">
                                            <feather-icon icon="XIcon" />
                                        </span>
                                    </b-form-checkbox>
                                    <b-form-text>{{$t('Quantities for products are synced every half hour automatically')}}</b-form-text>
                                </b-form-group>

                                <!-- shipping_product_id -->
                                <b-form-group
                                    :label="$t('Shippnig product id')"
                                    label-for="v-shipping_product_id"
                                >
                                    <b-form-input
                                        id="v-shipping_product_id"
                                        type="text"
                                        v-model="config.shipping_product_id"
                                    />
                                    <b-form-text>{{$t('Shipping price is added to this product')}}</b-form-text>
                                </b-form-group>

                                <!-- shipping_apply_vat -->
                                <b-form-group
                                :label="$t('Apply VAT on shipping price')"
                                label-for="v-shipping_apply_vat"
                                >
                                    <b-form-checkbox
                                        id="v-shipping_apply_vat"
                                        class="custom-control-primary"
                                        v-model="config.shipping_apply_vat"
                                        name="shipping_apply_vat"
                                        switch
                                    >
                                        <span class="switch-icon-left">
                                            <feather-icon icon="CheckIcon" />
                                        </span>
                                        <span class="switch-icon-right">
                                            <feather-icon icon="XIcon" />
                                        </span>
                                    </b-form-checkbox>
                                </b-form-group>

                                <!-- cod_product_id -->
                                <b-form-group
                                    :label="$t('Cash on delivery product id')"
                                    label-for="v-cod_product_id"
                                >
                                    <b-form-input
                                        id="v-cod_product_id"
                                        type="text"
                                        v-model="config.cod_product_id"
                                    />
                                    <b-form-text>{{$t('COD fee is added to this product')}}</b-form-text>
                                </b-form-group>

                                <!-- cod_apply_vat -->
                                <b-form-group
                                :label="$t('Apply VAT on cash on delivery fee')"
                                label-for="v-cod_apply_vat"
                                >
                                    <b-form-checkbox
                                        id="v-cod_apply_vat"
                                        class="custom-control-primary"
                                        v-model="config.cod_apply_vat"
                                        name="cod_apply_vat"
                                        switch
                                    >
                                        <span class="switch-icon-left">
                                            <feather-icon icon="CheckIcon" />
                                        </span>
                                        <span class="switch-icon-right">
                                            <feather-icon icon="XIcon" />
                                        </span>
                                    </b-form-checkbox>
                                </b-form-group>

                                <hr>

                                <h4 class="text-primary">{{$t('Payment Methods')}}</h4>  
                                <p>{{$t('To organize the accounting work, each payment method is assigned an account in the chart of accounts')}}</p> 


                                <!-- payment methods -->
                                <template v-if="payment_methods.length">
                                    <b-row>
                                    <template v-for="(payment_method, p_i) in payment_methods">
                                        <b-col cols="6" :key="p_i">
                                            <b-form-group
                                                :label="payment_method.shown_name"
                                                :label-for="`v-payment-method-`+payment_method.id"
                                            >
                                                <b-form-input
                                                    :id="`v-payment-method-`+payment_method.id"
                                                    type="text"
                                                    v-model="config.payment_account_id[payment_method.id]"
                                                />
                                            </b-form-group>
                                        </b-col>
                                    </template>
                                    </b-row>
                                </template>
                                <template v-else>
                                    {{$t('You do not have payment methods active')}}
                                </template>
    
                                
                                <!-- submit -->
                                <b-col cols="12" class="p-0 text-right">

                                <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    type="submit"
                                    variant="primary"
                                    class="ml-1"
                                >
                                    <template v-if="!isLoadingSave">
                                        {{$t('Save')}}
                                    </template>
                                    <template v-if="isLoadingSave">
                                        {{$t('Saveing')}}...
                                        <b-spinner small />
                                    </template>
                                </b-button>

                                </b-col>
                            </b-form>
                            
                        </b-card>

                        <b-card>
                            <b-form @submit.prevent="saveProductAndPackages()">
                                <!-- products -->
                                <h4 class="text-primary">{{$t('Products')}}</h4>  
                                <p>{{$t('In order for you to create the invoices correctly, the product number in Qoyod is added to the products on the store')}}</p> 
                                <template v-if="Object.keys(products).length > 0">
                                    <b-row>
                                    <template v-for="(product, pro_i) in products">
                                        <b-col cols="6" :key="pro_i">
                                            <b-form-group
                                                :label="product.name"
                                                :label-for="`v-product-`+product.id"
                                            >
                                                <b-form-input
                                                    :id="`v-product-`+product.id"
                                                    type="text"
                                                    v-model="product.custom_attributes.qoyod_id"
                                                />
                                            </b-form-group>
                                        </b-col>
                                    </template>
                                    </b-row>
                                </template>
                                <template v-else>
                                    {{$t('You do not have products')}}
                                </template>

                                <hr>

                                <!-- packages -->
                                <h4 class="text-primary">{{$t('Packages')}}</h4>  
                                <template v-if="Object.keys(packages).length > 0">
                                    <b-row>
                                    <template v-for="(thePackage, pkg_i) in packages">
                                        <b-col cols="6" :key="pkg_i">
                                            <b-form-group
                                                :label="thePackage.name"
                                                :label-for="`v-product-`+thePackage.id"
                                            >
                                                <b-form-input
                                                    :id="`v-product-`+thePackage.id"
                                                    type="text"
                                                    v-model="thePackage.custom_attributes.qoyod_id"
                                                />
                                            </b-form-group>
                                        </b-col>
                                    </template>
                                    </b-row>
                                </template>
                                <template v-else>
                                    {{$t('You do not have packages')}}
                                </template>

                                <!-- submit -->
                                <b-col cols="12" class="p-0 text-right">

                                    <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        type="submit"
                                        variant="primary"
                                        class="ml-1"
                                    >
                                        <template v-if="!isLoadingSave">
                                            {{$t('Save')}}
                                        </template>
                                        <template v-if="isLoadingSave">
                                            {{$t('Saveing')}}...
                                            <b-spinner small />
                                        </template>
                                    </b-button>

                                </b-col>
                            </b-form>
                        </b-card>
                    </b-col>
                </b-row>
            </transition>
        </b-overlay>
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
export default {
  components: { FeatherIcon },
    directives: {
        Ripple,
    },
    mounted() {
        this.getPaymentMethods()
        this.getProductsAndPackages()
        setTimeout(() => {
            this.getOtherServices()
        }, 2000);
    },
    data() {
        return {
            config: {
                is_active: false,
                api_key: '',
                apply_vat: '',
                sync_quantites: false,
                shipping_product_id: '',
                shipping_apply_vat: false,
                cod_product_id: '',
                cod_apply_vat: false,
                payment_account_id: []
            },
            vat_options: [
                {value: 'vat_included', text: this.$t('Product price included VAT')},
                {value: 'apply_vat', text: this.$t('Apply VAT on product price'), disabled: true},
                {value: 'no_vat', text: this.$t('Do not apply on product price')},
            ],
            payment_methods: [],
            products: {},
            packages: {},
            isLoading: true,
            isLoadingSave: false,
        }
    },
    methods: {
        getOtherServices(){
            this.isLoading = true
            useJwt.post('/store/config/get-by-id',{key: 'other_services_config'})
            .then((response) => {
                // console.log(response.data)
                if (response.data.data && response.data.data.other_services_config && response.data.data.other_services_config.other_services_active) {
                    this.other_services_config = response.data.data.other_services_config.other_services_active
                    this.getOtherServiceById(2) // 2 = Qoyod
                }
                this.isLoading = false
            })
            .catch(response => {
                // console.log(response);
            });
        },
        getOtherServiceById(id) {
            if (Object.keys(this.other_services_config).length) {
                let otherService = this.other_services_config.find(method => { return method.id == id })
                if (otherService) {
                    this.config = otherService;
                    this.config.is_active = this.config.is_active == "1" || this.config.is_active == true ? true : false
                    this.config.sync_quantites = this.config.sync_quantites == "1" || this.config.sync_quantites == true ? true : false
                }
            }
        },
        getPaymentMethods(){
            useJwt.get('/store/config/payment-methods')
            .then((response) => {
                // console.log(response.data)
                this.payment_methods = response.data.data
            })
            .catch(response => {
                // console.log(response);
            });
        },
        getProductsAndPackages(){
            useJwt.get('/store/config/other-services/qoyod/get-products-and-packages')
            .then((response) => {
                // console.log(response.data)
                this.products = response.data.data.products
                this.packages = response.data.data.packages

                this.products.forEach(product => {
                    if (!product.custom_attributes) {
                        product.custom_attributes = {}
                    }
                });
                this.packages.forEach(thePackage => {
                    if (!thePackage.custom_attributes) {
                        thePackage.custom_attributes = {}
                    }
                });
            })
            .catch(response => {
                // console.log(response);
            });
        },
        save() {
            this.isLoadingSave = true;
            this.config.id = 2; // 2 = Qoyod
            this.config.store_id = this.$store.state.store.id
            useJwt.post('/store/config/other-services/save',this.config)
            .then((response) => {
                // console.log(response.data);
                this.isLoadingSave = false
                this.$root.showToast('success', 'save', '')
            })
            .catch(error => {
                // console.log(response);
                this.isLoadingSave = false
                this.$root.showAlert('error', error.response.data.message)
            });
        },
        saveProductAndPackages() {
            this.isLoadingSave = true;
            let form = {
                products: this.products, 
                packages: this.packages
            }
            useJwt.post('/store/config/other-services/qoyod/save-products-and-packages',form)
            .then((response) => {
                // console.log(response.data);
                this.isLoadingSave = false
                this.$root.showToast('success', 'save', '')
            })
            .catch(error => {
                // console.log(response);
                this.isLoadingSave = false
                this.$root.showAlert('error', error.response.data.message)
            });
        }
    },
}
</script>

<style lang="sass" scoped>
    .demo-inline-spacing > *
        margin-top: 0
</style>
